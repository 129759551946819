<template>
  <AchOverviewLayout
    @onCancel="hideModal"
    @onConfirmDeposit="confirmDeposit"
    :achMethod="lodashGet(ongoingDepositData, 'methodData', {})"
    :amount="lodashGet(ongoingDepositData, 'amount', 0)"
    :signatureText="lodashGet(ongoingDepositData, 'requestAchSignature.text', '')"
    :isLoading="isLoading"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AchOverviewLayout from './layout';
import MethodEventName from '@/enums/MethodEventName';

export default {
  name: 'AchOverview',
  components: {
    AchOverviewLayout,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  mounted() {
    this.$store.dispatch('events/track', {
      event: 'DEPOSIT_ACH_AUTHORIZATION_VIEWED',
    });
  },

  computed: {
    ...mapState('wallet', ['ongoingDepositData']),
  },

  methods: {
    ...mapActions('wallet', ['handleAchDeposit']),
    ...mapActions('ui', ['setIsOverlayLoading']),

    formatCurrency(number) {
      return this.numberFormat(number, 2, false, true);
    },

    async confirmDeposit() {
      try {
        this.isLoading = true;
        this.setIsOverlayLoading(true);
        this.$store.dispatch('events/track', {
          event: 'DEPOSIT_SUBMITTED',
          variables: {
            amount: this.formatCurrency(this.lodashGet(this.ongoingDepositData, 'amount', 0)),
            fee: this.formatCurrency(this.lodashGet(this.ongoingDepositData, 'fee', 0)),
            payment_type: MethodEventName.ach,
          },
        });
        await this.handleAchDeposit();
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);
      } finally {
        this.isLoading = false;
        this.setIsOverlayLoading(false);
      }
    },
  }
};
</script>

<style scoped>
</style>
