<template>
  <div class="mb-s20">
    <div class="headline-small">
      {{ $t('wallet.modal.ach_overview.title') }}
    </div>

    <div class="flex justify-between items-center mt-s40 pb-s16 border-border border-b">
      <span class="body-text-x-large">
        {{ $t('wallet.modal.ach_overview.amount') }}
      </span>
      <span class="body-text-x-large text-text-positive">
        {{ numberFormat(amount, 2, false, true) }}
      </span>
    </div>

    <div class="flex justify-between items-center mt-s16 pb-s16 border-border border-b">
      <span class="body-text-x-large">
        {{ $t('date') }}
      </span>
      <span class="body-text-x-large">
        {{date}}
      </span>
    </div>

    <div class="flex justify-between items-center mt-s16 pb-s16 border-border border-b">
      <span class="body-text-x-large">
        {{ $t('wallet.modal.ach_overview.source') }}
      </span>
      <span class="body-text-x-large">
        {{ achName }}
      </span>
    </div>

    <div class="flex justify-between items-center mt-s16 pb-s16 border-border border-b">
      <span class="body-text-x-large">
        {{ $t('wallet.modal.ach_overview.available_to_trade') }}
      </span>
      <span class="body-text-x-large">
        {{ $t('wallet.modal.ach_overview.instant') }}
      </span>
    </div>

    <div class="container mt-s16 body-text-medium">
      {{ signatureText }}
    </div>

    <div class="container mt-s48">
      <Checkbox
        v-model="userAgrees"
        :disabled="!signatureText"
        :label="$t('wallet.modal.ach_overview.i_agree')"
        testId="user-agrees"
      />
    </div>

    <ButtonV2
      class="mt-s24"
      @onClick="$emit('onConfirmDeposit')"
      :inactive="isLoading || !userAgrees"
      :label="$t('wallet.modal.ach_overview.confirm_deposit')"
      testId="btn-confirm_deposit"
      size="medium"
      wide
    />

    <ButtonV2
      class="mt-s16"
      @onClick="$emit('onCancel')"
      :inactive="isLoading"
      :label="$t('cancel')"
      testId="btn-cancel"
      version="secondary"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import { ButtonV2, Checkbox } from '@/components/misc';
import { DateTime } from 'luxon';

export default {
  name: 'AchOverviewLayout',
  components: {
    ButtonV2,
    Checkbox,
  },
  data() {
    return {
      userAgrees: false,
    };
  },
  props: {
    achMethod: {
      type: Object,
    },
    amount: {
      type: [Number, String],
    },
    signatureText: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    achName() {
      return this.lodashGet(this.achMethod, 'nickname') || this.lodashGet(this.achMethod, 'description');
    },
    date() {
      return DateTime.now().toFormat('MM/dd/yyyy');
    }
  },
};
</script>

<style scoped>
.container {
  @apply flex justify-between items-center;
  max-width: 410px;
}
</style>
